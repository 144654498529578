import styled from 'styled-components'

export const FooterP = styled.p`
  color: #999;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 20px;
  padding-top: 300px;
  padding-left: 30px;

  @media screen and (max-width: 768px) {
    font-size: 13px;
    padding-top: 200px;
  }
`
