import React from 'react'
import { FooterP } from './FooterElements' 

const FooterSection = () => {
  return (
    <FooterP>Designed and built by Sieow Je Min <br/><br/> Copyright © 2023</FooterP>
  )
}

export default FooterSection;
